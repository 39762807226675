import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { cities } from "../data/cities";
import {
  scheduleKey,
  addressKey,
  addressIdKey,
  streetsUrl,
  numbersUrl,
  scheduleURL,
} from "../helpers/config";

type City = {
  id: string | number;
  value: string;
};

type Street = City;
type Address = City;

export function useGetSchedule() {
  const [cityId, setCityId] = useState<string | null>(null);
  const [streets, setStreets] = useState<Street[] | null>(null);
  const [streetId, setStreetId] = useState<string | null>(null);
  const [streetName, setStreetName] = useState<string | null>(null);
  const [addresses, setAddresses] = useState<Address[] | null>(null);
  const [addressId, setAddressId] = useState<string | null>(() => {
    if (localStorage.getItem(addressIdKey)) {
      return localStorage.getItem(addressIdKey);
    }
    return null;
  });
  const [addressLabel, setAddressLabel] = useState<string | null>(null);
  const [fullAddress, setFullAddress] = useState<string | null>(() => {
    if (localStorage.getItem(addressKey)) {
      return localStorage.getItem(addressKey);
    }
    return "";
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<any>(() => {
    if (localStorage.getItem(addressIdKey)) {
      return JSON.parse(localStorage.getItem(scheduleKey) ?? "[]");
    }
    return [];
  });

  const formattedCities = useMemo(() => {
    return cities.map((item: City) => ({
      value: item.id,
      label: item.value,
    }));
  }, []);

  const formattedStreets = useMemo(() => {
    if (streets !== null) {
      return streets.map((item: Street) => ({
        value: item.id,
        label: item.value,
      }));
    }
    return null;
  }, [streets]);

  const formattedNumbers = useMemo(() => {
    if (addresses !== null) {
      return addresses.map((item: Address) => ({
        value: item.id,
        label: item.value,
      }));
    }
    return null;
  }, [addresses]);

  // get streets for city
  useEffect(() => {
    if (cityId && !addressId) {
      axios.get(`${streetsUrl}/${cityId}`).then((response) => {
        setStreets(response.data);
      });
    }
  }, [cityId, addressId]);

  // get numbers for streets
  useEffect(() => {
    if (cityId && streetId && !addressId) {
      axios.get(`${numbersUrl}/${cityId}/${streetId}`).then((response) => {
        setAddresses(response.data);
      });
    }
  }, [streetId, cityId, addressId]);

  useEffect(() => {
    if (addressId !== null) {
      setIsLoading(true);
      axios
        .get(scheduleURL, {
          params: {
            id: addressId,
          },
        })
        .then((response) => {
          setSchedule(response.data.data ?? []);
          setIsLoading(false);
          localStorage.setItem(addressIdKey, addressId);

          if (streetName && addressLabel) {
            localStorage.setItem(
              addressKey,
              `ul. ${streetName} ${addressLabel}`,
            );
            setFullAddress(`ul. ${streetName} ${addressLabel}`);
          }
          resetState();
        });
    }
  }, [addressId, addressLabel, streetName]);

  function resetState() {
    setCityId(null);
    setStreetId(null);
    setAddressId(null);
    setAddresses(null);
  }

  return {
    isLoading,
    fullAddress,
    schedule,
    cityId,
    formattedCities,
    formattedStreets,
    addresses,
    formattedNumbers,
    setAddressId,
    setAddressLabel,
    setCityId,
    setStreetId,
    setStreetName,
    setAddresses,
    resetState,
  };
}
