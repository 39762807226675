import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import pl from "dayjs/locale/pl";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./theme";
import { GlobalStyles } from "./components/GlobalStyles";
import { Cookies } from "./components/Cookies";
import { News } from "./components/News";
import { useDarkMode } from "./hooks/useDarkMode";
import { Home } from "./views/Home/Home";
import { ScheduleProvider } from "./context/ScheduleProvider";

dayjs.extend(utc);
dayjs.locale(pl);

function App() {
  const [theme] = useDarkMode();

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <ScheduleProvider>
        <GlobalStyles />
        <Home />
        {/* <News name="20231219" title="Drodzy uyżytkownicy" buyCoffe={true}>
          <p>
            Dziękuję Wam za kolejny wspólny rok! Wasze zaangażowanie i opinie
            sprawiły, że aplikacja ciągle się rozwija. Niech nadchodzący rok
            przyniesie nam jeszcze więcej sukcesów i wspaniałych chwil.
          </p>
        </News> */}
        <Cookies />
      </ScheduleProvider>
    </ThemeProvider>
  );
}

export default App;
