import {
  Button,
  Container,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { Title, BigDate, Loader } from "../../components/Styled";
import gearIcon from "../../assets/gear.svg";
import {
  Calendar,
  ScheduleList,
  BuyCoffeeButton,
  Copyright,
  Sun,
  SelectAddressForm,
  StringChanger,
} from "../../components";
import { lightTheme } from "../../theme";
import { useSchedule } from "../../context/ScheduleProvider";
import Snowfall from "react-snowfall";
import { When, If, Then, Else } from "react-if";
import { useToday } from "../../hooks/useToday";
import { getMonthImage } from "../../helpers/helpers";

export function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { today, dayMonth, isDecember, isOctober, monthNumber } = useToday();
  const { isLoading, fullAddress, schedule, resetState } = useSchedule();

  const handleToggle = () => (isOpen ? onClose() : onOpen());
  const isSnowVisible = isDecember || isOctober;
  

  return (
    <>
      <When condition={isSnowVisible}>
        <Snowfall style={{ zIndex: 1 }} />
      </When>
      <div>
        <Container
          maxW="container.md"
          overflow="visible"
          css={{
            position: "relative",
            height: "14rem",
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%), url(${getMonthImage(
              monthNumber,
            )}), linear-gradient(180deg, rgba(43,143,152,1) 0%, rgba(43,143,152,1) 78%, #edf1f4 78%, #edf1f4 100%)`,
            backgroundSize: "cover",
          }}
        >
          <Flex pt={4} alignItems="flex-start" justifyContent="space-between">
            <Flex flexDirection="column">
              <Title>{fullAddress}</Title>
              <BigDate>{dayMonth}</BigDate>
              <Sun />
            </Flex>
            <Flex
              alignItems="flex-start"
              justifyContent="flex-end"
              flexWrap="wrap"
            >
              <BuyCoffeeButton />
              <Button
                ml={2}
                mt={2}
                size="xs"
                color="#ffffff"
                onClick={handleToggle}
                backgroundColor="transparent"
              >
                <img
                  src={gearIcon}
                  width="16"
                  height="16"
                  style={{ marginRight: "0.25rem" }}
                  alt="ustawienia"
                />
                Ustawienia
              </Button>
            </Flex>
          </Flex>
          <Calendar schedule={schedule} today={today} />
        </Container>
      </div>
      <If condition={isLoading}>
        <Then>
          <Container
            minHeight="calc(100vh - 220px)"
            maxW="container.md"
            overflow="hidden"
            backgroundColor="#edf1f4"
            display="flex"
            flex={1}
            padding="100px 1rem 0px 1rem"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Loader>
              <Spinner mr={2} />
              <StringChanger />
              {/* <span>{result}...</span> */}
            </Loader>
            <Copyright />
          </Container>
        </Then>
        <Else>
          <Container
            maxW="container.md"
            overflow="hidden"
            backgroundColor="#edf1f4"
            display="flex"
            flex={1}
            flexDirection="column"
          >
            <>
              {schedule.length > 0 ? (
                <>
                  <ScheduleList schedule={schedule} today={today} />
                  <Copyright />
                </>
              ) : (
                <div
                  style={{
                    height: "100vh",
                    padding: "3rem",
                  }}
                >
                  <SelectAddressForm />
                </div>
              )}
            </>
          </Container>
        </Else>
      </If>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          resetState();
        }}
        size="sm"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={lightTheme.text}>Ustawienia</ModalHeader>
          <ModalCloseButton
            color={lightTheme.text}
            backgroundColor={lightTheme.background2}
          />
          <ModalBody>
            <SelectAddressForm />
          </ModalBody>
          <ModalFooter mt={6} justifyContent={"space-between"}>
            <BuyCoffeeButton />
            <Button
              backgroundColor={lightTheme.background2}
              color={lightTheme.text}
              onClick={() => {
                onClose();
                resetState();
              }}
            >
              Zamknij
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
